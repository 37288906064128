import { StyleSheet, FlatList, TouchableOpacity, Image } from "react-native";
import { Text, View } from "../components/Themed";
import { RootTabScreenProps } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { useEffect, useState } from "react";
import { fetchProcedures } from "../reducers/procedureSlice";
import { Dimensions } from "react-native";

import { LinearGradient } from "expo-linear-gradient";
const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

import Tabs from "../components/Tabs";
import Tiles from "../components/Tiles";
import VideoProcedure from "./Video";
import React from "react";

export default function TabOneScreen({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const dispatch = useDispatch<AppDispatch>();

  const screenState = useSelector((state: RootState) => state.procedureList);

  useEffect(() => {
    dispatch(fetchProcedures());
  }, []);

  // const client = sanityClient({
  //   projectId: "bvas6ydi",
  //   dataset: "production",
  //   apiVersion: "2022-10-23",
  //   token:
  //     "skvcKtSsI2xBekJe6tSzEFW4W2pZ7AloBah3YYPVJNHaNlCWD6ycoKwK6FiyIpkIDkf1XTBJdpbRb7LUXoMHo84WonjfqsQ8bX9c6esvsvqYlLLZFSALMeML4PVej6dSQ41lUjsN9kB75lL3xlW0xPoq5OefFJ03s1AWiKGTaKbYPMQ6qYUd", // or leave blank for unauthenticated usage
  //   useCdn: false,
  // });
  // const doc = {
  //   _id: "my-bike",
  //   _type: "bike",
  //   title: "Sanity Tandem Extraordinaire",
  // };
  // try {
  //   client.create(doc).then((res) => {
  //     console.log(res);
  //   });
  // } catch (e) {
  //   console.log(e);
  // }

  return (
    <View style={styles.container}>
      <LinearGradient colors={["#f2f2f2ff", "#f2f2f2ff"]} style={[styles.top]}>
        <Image
          source={require("../assets/images/logo.png")}
          style={{
            height: 50,
            width: 150,
            position: "absolute",
            left: 0,
            top: 10,
            zIndex: 999,
          }}
          resizeMode="contain"
        />
      </LinearGradient>
      <LinearGradient
        colors={["#f2f2f2", "#f2f2f200"]}
        style={[styles.top, { top: 50 }]}
      />
      <LinearGradient
        colors={["#f2f2f200", "#f2f2f2ff"]}
        style={styles.bottom}
      />
      <Tabs />
      <Tiles />
      <VideoProcedure />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
  },
  top: {
    width: windowWidth,
    height: 50,
    position: "absolute",
    zIndex: 999,
  },
  bottom: {
    bottom: 0,
    width: 50,
    height: 150,
    position: "absolute",
    zIndex: 999,
  },
});
