import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiClient from "../apiClient";

export type Procedure = {
  category: string;
  title: string;
  video: string;
  prep: Array<string>;
  prepTitle: Array<string>;
  sequences: Array<string>;
  sequencesTitle: Array<string>;
  care: Array<string>;
  tips: Array<string>;
  peer: Array<string>;
  discussion: Array<string>;
  references: Array<string>;
  _id: string;
};

export type ProcedureListState = {
  procedures: Procedure[];
  loading: boolean;
  error: boolean;
};

const initialState: ProcedureListState = {
  procedures: [
    {
      _id: "9e039405-122c-468e-a19f-398ecb395569",
      care: "Definitive airway management if required",
      category: "Airway",
      discussion:
        "All airway manoeuvres are associated with some movement of the cervical spine. In suspected C-spine injury manual in-line stabilisation of the C-spine should be provided with jaw thrust preferred and chin-lift added only if jaw thrust fails. While we always try to protect the spine, it is important to remember opening the airway takes precedence over spinal or other injuries.",
      image:
        "https://i.vimeocdn.com/video/1526636991-ece963202c74b53a4d76348216cacb56f7fce1b8950995d60d6823973f9b30ff-d_640",
      peer: "This guideline has been reviewed and approved by the following expert groups:\n\nEmergency Care Institute\n\nPlease direct feedback for this procedure to feedback@emergencyprocedures.org\n",
      prep: [
        "Upper airway obstruction (partial or complete)",
        "None",
        "Oropharyngeal airway\nNasopharyngeal airway\nNon-invasive positive pressure ventilation\nLaryngeal mask airway\nIntubation",
        "Consent is not required\nThis is an emergency procedure to save a life",
        "Pain\nFailure to open airway",
        "Standard precautions\nPPE: non-sterile gloves",
        "Any",
        "Procedural Clinican",
        "Supine",
      ],
      prepTitle: [
        "Indications",
        "Contraindications (ABSOLUTE/relative)",
        "Alternatives",
        "Consent",
        "Potential complications",
        "Infection Control",
        "Area",
        "Staff",
        "Positioning",
      ],
      references:
        "Australian Resuscitation Council and New Zealand Resuscitation Council. ANZCOR guideline 4 – airway. Melbourne: Australian Resuscitation Council and New Zealand Resuscitation Council; 2016. 7pp. Available from https://resus.org.au/guidelines/\n\nRoberts JR, Custalow CB, Thomsen TW. Roberts and Hedges' clinical procedures in emergency medicine and acute care. 7th ed. Philadelphia, PA: Elsevier; 2019.\n\nDunn RJ, Borland M, O'Brien D (eds.). The emergency medicine manual. Online ed. Tennyson, SA: Venom Publishing; 2019.\n\nWittels KA. Basic airway management in adults. In: UpToDate. Waltham (MA): UpToDate. 2019 Sept 17. Retrieved March 2019. Available from: https://www.uptodate.com/contents/basic-airway-management-in-adults\n\nGuildner CW. Resuscitation - opening the airway. A comparative study of techniques for opening an airway obstructed by the tongue. JACEP. 1976;5(8):588. DOI: 10.1016/s0361-1124(76)80217-1\n",
      sequences: [
        "Place one hand on the patient’s forehead and tilt the patients head backwards\n\nLift the mandible at the mentum using index and middle fingertips (avoiding soft tissue compression)\n\nSimultaneously apply downward pressure to the patient’s forehead\n\nThe thumb may be used to grip the chin aiding chin-lift and mouth opening",
        "Place index and middle fingertips behind angle of the mandible on each side\n\nPlace the thenar eminence of each hand over the zygomatic arch\n\nPull mandible anteriorly until lower incisors are anterior to upper incisors\n\nThe thumb may be used to grip to aid mouth opening",
        "Roll patient onto side, open mouth and turn head downwards to allow material to drain\n\nManual C-spine immobilisation and open mouth if C-spine injury suspected\n\nLarge-bore (more effective) or Yankauer (reduced trauma) suction of secretions\n\nRemove loose dentures but allow well-fitting dentures to remain",
      ],
      sequencesTitle: [
        "Head-tilt, Chin-lift",
        "Jaw thrust",
        "Airway obstruction from fluid or matter",
      ],
      tips: "Obese patients require increased head elevation (ramping) to achieve the sniffing position",
      title: "Airway Manoeuvres",
      video:
        "https://player.vimeo.com/progressive_redirect/playback/678405277/rendition/540p/file.mp4?loc=external\u0026signature=0e59e0b0d4f2b2758e9b47bdb7f07518413644d72c405a8d78fdae59eb431017",
    },
  ],
  loading: false,
  error: false,
};

export const fetchProcedures = createAsyncThunk<{ procedures: Procedure[] }>(
  "fetchProcedures",
  async () => {
    const response = await apiClient.fetchProcedures();
    if (response.kind === "success") {
      return {
        procedures: response.body ?? [],
      };
    } else {
      throw "Error fetching uers";
    }
  }
);

const procedureSlice = createSlice({
  name: "procedures",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProcedures.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchProcedures.fulfilled, (state, action) => {
        state.procedures = action.payload.procedures;
        state.loading = false;
      })
      .addCase(fetchProcedures.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});

export default procedureSlice.reducer;
