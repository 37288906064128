import { combineReducers, configureStore } from "@reduxjs/toolkit";
import procedureSlice from "./reducers/procedureSlice";
import generalSlice from "./reducers/generalSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

export type AppDispatch = typeof store.dispatch;
//AsyncStorage.clear();

const rootReducer = combineReducers({
  procedureList: procedureSlice,
  generalList: generalSlice,
});
export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
