import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  Dimensions,
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native";
import { ResizeMode } from "expo-av";
import VideoPlayer from "expo-video-player";
import { Text, View } from "../components/Themed";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { BlurView } from "expo-blur";
import { useWindowDimensions } from "react-native";
import { view } from "../reducers/generalSlice";
import sanityClient from "@sanity/client";
import { Video, AVPlaybackStatus } from "expo-av";
import { StatusBar } from "expo-status-bar";

export default function VideoProcedure() {
  //const video = React.useRef(null);

  const navigation = useNavigation();
  const [index, setIndex] = useState(0);
  const [visible, setVisible] = useState(true);
  const screenState = useSelector(
    (state: RootState) => state.procedureList.procedures
  );
  const selectedVideo = useSelector(
    (state: RootState) => state.generalList.video
  );
  const consented = useSelector(
    (state: RootState) => state.generalList.consented
  );
  const user = useSelector((state: RootState) => state.generalList);
  const views = useSelector((state: RootState) => state.generalList.viewed);
  const first = !views?.includes(selectedVideo);
  let procedure = screenState.filter((video) => video._id == selectedVideo);
  const Tab = createMaterialTopTabNavigator();
  const [inFullscreen, setInFullscreen] = useState(false);
  const [perform, setPerform] = useState("No");
  const [performed, setPerformed] = useState("Never");

  const dispatch = useDispatch<AppDispatch>();

  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  const client = sanityClient({
    projectId: "bvas6ydi",
    dataset: "production",
    apiVersion: "2022-10-23",
    token:
      "skvcKtSsI2xBekJe6tSzEFW4W2pZ7AloBah3YYPVJNHaNlCWD6ycoKwK6FiyIpkIDkf1XTBJdpbRb7LUXoMHo84WonjfqsQ8bX9c6esvsvqYlLLZFSALMeML4PVej6dSQ41lUjsN9kB75lL3xlW0xPoq5OefFJ03s1AWiKGTaKbYPMQ6qYUd", // or leave blank for unauthenticated usage
    useCdn: false,
  });

  /*useEffect(() => {
    navigation.addListener("focus", () => {
      console.log("modal");
      ScreenOrientation.unlockAsync(); /*.then(() => {
          ScreenOrientation.addOrientationChangeListener((e) => {
            console.log("test2");
            if (
              e.orientationInfo.orientation === 3 ||
              e.orientationInfo.orientation === 4
            ) {
              setInFullsreen(true);
              console.log("true");
              return;
            } else {
              console.log("false");
              setInFullsreen(false);
              return;
            }
          });
        });
    }),
      //ScreenOrientation.removeOrientationChangeListeners();
      //ScreenOrientation.lockAsync(3);
  []);*/

  const Header = () => <View></View>;

  var prep: JSX.Element[] = [];
  const length = procedure[0].prep.length;
  for (let i = 2; i < length; i++) {
    prep.push(
      <View
        style={{
          borderBottomWidth: i == length - 1 ? 0 : 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginLeft: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            {procedure[0].prepTitle[i]}
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].prep[i]}
          </Text>
        </View>
      </View>
    );
  }

  var sequence: JSX.Element[] = [];
  const lengthS = procedure[0].sequences.length;
  for (let i = 0; i < lengthS; i++) {
    sequence.push(
      <View
        style={{
          borderBottomWidth: i == lengthS - 1 ? 0 : 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            {procedure[0].sequencesTitle[i]}
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].sequences[i]}
          </Text>
        </View>
      </View>
    );
  }

  const Sequences = () => (
    <ScrollView>
      {sequence}
      {first && <View style={{ height: 100 }}></View>}
    </ScrollView>
  );

  const Post = () => (
    <ScrollView>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Post-procedure care
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].care}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Tips
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].tips}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Discussion
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].discussion}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Peer Review
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].peer}
          </Text>
        </View>
      </View>
      <View
        style={{
          paddingTop: 10,
          paddingBottom: 15,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            References
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].references}
          </Text>
        </View>
      </View>
      {first && <View style={{ height: 100 }}></View>}
    </ScrollView>
  );

  const Preparation = () => (
    <ScrollView>
      <View style={{ flexDirection: "row" }}>
        <Feather
          name="check-circle"
          size={18}
          color="black"
          style={{ marginLeft: 15, marginTop: 15 }}
        />
        <View>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 15 }}>
            Indication:
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5, marginRight: 15 }}>
            {procedure[0].prep[0]}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          borderBottomWidth: 1,
          paddingBottom: 10,
          borderColor: "#eaeaea",
        }}
      >
        <Feather
          name="x-circle"
          size={18}
          color="black"
          style={{ marginLeft: 15, marginTop: 5 }}
        />
        <View>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Contraindications (ABSOLUTE/relative):
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5, marginRight: 15 }}>
            {procedure[0].prep[1]}
          </Text>
        </View>
      </View>
      {prep}
      {first && <View style={{ height: 100 }}></View>}
    </ScrollView>
  );

  const videoWidth = Math.min(windowWidth - 500, 600);

  return (
    <View style={styles.container}>
      <View
        style={{
          width: videoWidth,
          borderRadius: 15,
          overflow: "hidden",
          height: Dimensions.get("window").height - 130,
          top: 20,
        }}
      >
        <Video
          style={{
            width: videoWidth,
            height: videoWidth * 0.5625,
          }}
          resizeMode="cover"
          useNativeControls
          source={{
            uri: procedure[0].video,
          }}
          videoStyle={{
            width: videoWidth,
            height: videoWidth * 0.5625,
          }}
        />
        <Tab.Navigator
          screenOptions={{
            tabBarScrollEnabled: false,
            tabBarIndicatorStyle: {
              backgroundColor: "#415BFA",
            },
            tabBarStyle: {},
            tabBarLabelStyle: {
              color: "black",
              textTransform: "none",
              fontWeight: "bold",
            },
          }}
          sceneContainerStyle={{ backgroundColor: "white" }}
        >
          <Tab.Screen name={"Preparation"} component={Preparation} />
          <Tab.Screen name={"Sequence"} component={Sequences} />
          <Tab.Screen name={"Post Procedure"} component={Post} />
        </Tab.Navigator>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    left: 480,
    top: 95,
    width: Dimensions.get("window").width - 500,
    height: Dimensions.get("window").height - 95,
    alignItems: "center",
    backgroundColor: "#e9e9e9",
    borderRadius: 15,
  },
  tabBar: {
    flexDirection: "row",
  },
  tabItem: {
    flex: 1,
    alignItems: "center",
    padding: 16,
  },
});
