import { TouchableOpacity, FlatList, StyleSheet, Text } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { useEffect, useState, useRef } from "react";
import { category } from "../reducers/generalSlice";
import { Dimensions } from "react-native";

export default function Tabs() {
  const dispatch = useDispatch<AppDispatch>();
  const selectedCategory = useSelector(
    (state: RootState) => state.generalList.category
  );

  const windowHeight = Dimensions.get("window").height;

  const DATA = [
    {
      id: 0,
      title: "Airway",
    },
    {
      id: 2,
      title: "Breathing",
    },
    {
      id: 4,
      title: "Circulation",
    },
    {
      id: 3,
      title: "Burns",
    },
    {
      id: 9,
      title: "Immobilisation",
    },
    {
      id: 17,
      title: "Intubation",
    },
    {
      id: 18,
      title: "Joint Aspiration",
    },
    {
      id: 12,
      title: "Joint Reduction",
    },
    {
      id: 10,
      title: "Microbiology",
    },
    {
      id: 19,
      title: "Neurology",
    },
    {
      id: 20,
      title: "Nerve Blocks",
    },
    {
      id: 11,
      title: "Obs and Gynae",
    },
    {
      id: 6,
      title: "Ophthalmology",
    },
    {
      id: 13,
      title: "Surgical",
    },
    {
      id: 22,
      title: "Suturing",
    },
    {
      id: 23,
      title: "Trauma",
    },
    {
      id: 15,
      title: "Ultrasound",
    },
    {
      id: 24,
      title: "Urology",
    },
    {
      id: 16,
      title: "Vertigo",
    },
  ];
  const Item = ({ item, onPress, backgroundColor, textColor }) => (
    <TouchableOpacity onPress={onPress} style={[styles.item, backgroundColor]}>
      <Text style={[styles.title, textColor]}>{item.title}</Text>
    </TouchableOpacity>
  );

  const lis = ["Airway", "Breathing", "Circulation"];

  const renderItem = ({ item }) => {
    const backgroundColor =
      item.title === selectedCategory
        ? "#415BFA"
        : lis.includes(item.title)
        ? "#d5d5d5"
        : "white";
    const color = item.title === selectedCategory ? "white" : "#555";

    return (
      <Item
        item={item}
        onPress={() => {
          dispatch(category(item.title));
        }}
        backgroundColor={{ backgroundColor }}
        textColor={{ color }}
      />
    );
  };

  return (
    <FlatList
      data={DATA}
      renderItem={renderItem}
      //keyExtractor={(item) => item.id}
      showsVerticalScrollIndicator={false}
      style={{ width: 57, height: windowHeight, left: 0 }}
      contentContainerStyle={{
        paddingBottom: 50,
        paddingTop: 80,
      }}
      getItemLayout={(data, index) => ({
        length: 175,
        offset: 187 * index,
        index,
      })}
      initialScrollIndex={DATA.findIndex((x) => x.title === selectedCategory)}
    />
  );
}

const styles = StyleSheet.create({
  item: {
    //backgroundColor: "white",
    marginTop: 12,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    alignContent: "center",
    width: 40,
    height: 175,
  },
  title: {
    transform: [
      { rotate: "-90deg" },
      { translateY: 40 / 2 - 175 / 2 + 10 },
      { translateX: -(175 / 2 - 40 / 2) },
    ],
    fontFamily: "Arial",
    fontWeight: "700",
    fontSize: 14,
    color: "#555",
    height: 40,
    width: 175,
    textAlign: "center",
  },
});
