/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import {
  TransitionPresets,
  createStackNavigator,
} from "@react-navigation/stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";

import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import TabOneScreen from "../screens/Procedures";
import { RootStackParamList } from "../../types";
import LinkingConfiguration from "./LinkingConfiguration";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  const screenState = useSelector((state: RootState) => state.generalList);

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DefaultTheme : DefaultTheme}
    >
      <Stack.Navigator>
        <Stack.Screen
          name="Procedures"
          component={TabOneScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
        <Stack.Group>
          <Stack.Screen
            options={{
              headerShown: false,
              //gestureEnabled: true,
              presentation: "modal",
              ...TransitionPresets.ModalPresentationIOS,
            }}
            name="Modal"
            component={ModalScreen}
          />
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createStackNavigator<RootStackParamList>();

type options = {};
