import {
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Text,
  Image,
  View,
  Dimensions,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { video } from "../reducers/generalSlice";

import { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

export default function Tiles() {
  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();

  const selectedCategory = useSelector(
    (state: RootState) => state.generalList.category
  );

  const selectedVideo = useSelector(
    (state: RootState) => state.generalList.video
  );

  const screenState = useSelector((state: RootState) => state.procedureList);
  const VideoTile = ({ item, index, onPress }) => (
    <View>
      <TouchableOpacity
        onPress={() => {
          dispatch(video(item._id));
          //navigation.navigate("Modal");
        }}
        style={[
          styles.tile,
          {
            overflow: "hidden",
            marginTop: index == 0 ? 93 : 25,
            borderColor: "#415BFA",
            borderWidth: item._id == selectedVideo ? 8 : 0,
          },
        ]}
      >
        <Image
          source={{
            uri: item.image,
          }}
          style={{
            width: 500,
            height: 300,
            position: "absolute",
          }}
        ></Image>
        <View
          style={{
            width: windowWidth,
            height: 200,
            position: "absolute",
            backgroundColor: "#00000090",
          }}
        ></View>
        <Text
          style={[
            styles.videoTitle,
            {
              marginLeft: 10,
              color: "white",
              position: "absolute",
              left: 0,
              top: 0,
            },
          ]}
        >
          {item.title}
        </Text>
        <Feather
          name="play-circle"
          size={50}
          color="white"
          style={{ zIndex: 9999 }}
        />
      </TouchableOpacity>
      <Text style={styles.videoTitle}>{item.title}</Text>
      <Text numberOfLines={1} style={styles.videoIndication}>
        <Text>Indications:</Text>{" "}
        {item.prep[0].length > 80
          ? item.prep[0].substring(0, 80) + " ..."
          : item.prep[0]}
      </Text>
    </View>
  );

  const renderVideo = ({ item, index }) => {
    return <VideoTile item={item} index={index} />;
  };

  return (
    <FlatList
      data={screenState.procedures.filter(
        (procedure) => procedure.category == selectedCategory
      )}
      renderItem={renderVideo}
      //extraData={selectedId}
      showsVerticalScrollIndicator={false}
      style={{
        //display: key == selectedId ? "flex" : "none",
        width: 400,
        top: 0,
        height: windowHeight,
        left: 60,
        position: "absolute",
      }}
      contentContainerStyle={{ paddingBottom: 50, paddingTop: 0 }}
    />
  );
}

const styles = StyleSheet.create({
  tile: {
    width: 400,
    height: 200,
    minHeight: 200,
    backgroundColor: "#4287f5",
    borderRadius: 15,
    marginTop: 30,
    shadowColor: "#00000050",
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 8.0,
    alignItems: "center",
    justifyContent: "center",
  },
  videoTitle: {
    marginTop: 10,
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  videoIndication: {
    fontSize: 10,
    fontFamily: "sans-serif",
    width: "80%",
  },
  bold: {
    fontWeight: "700",
  },
});
