import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type generalState = {
  category: string;
  video: string;
  loggedIn: boolean;
  fName: string;
  lName: string;
  email: string;
  country: string;
  level: string;
  years: string;
  consented: boolean;
  viewed: string[];
};

const initialState: generalState = {
  category: "Airway",
  video: "9e039405-122c-468e-a19f-398ecb395569",
  loggedIn: false,
  fName: "",
  lName: "",
  email: "",
  country: "",
  level: "",
  years: "",
  consented: true,
  viewed: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    login(state, action: PayloadAction<any>) {
      state.loggedIn = true;
      state.country = action.payload.country;
      state.fName = action.payload.fname;
      state.lName = action.payload.lname;
      state.email = action.payload.email;
      state.level = action.payload.level;
      state.years = action.payload.experience;
      state.consented = action.payload.consented;
    },
    category(state, action: PayloadAction<string>) {
      state.category = action.payload;
    },
    video(state, action: PayloadAction<string>) {
      state.video = action.payload;
    },
    view(state, action: PayloadAction<string>) {
      state.viewed.push(action.payload);
    },
  },
});

export const { login, category, video, view } = generalSlice.actions;
export default generalSlice.reducer;
