import {Procedure} from "./reducers/procedureSlice";

type ResponseKind = 'success' | 'failure';

type NetworkResponse<T> = {
    kind: ResponseKind;
    body?: T;
}

export const fetchProcedures = async (): Promise<NetworkResponse<Procedure[]>> => {
    const response = await fetch(
        "https://bvas6ydi.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20'procedure'%5D%20%7B_id%2C'references'%3Areferences%5B0%5D.children%5B0%5D.text%2C'peer'%3Apeer%5B0%5D.children%5B0%5D.text%2C'discussion'%3Adiscussion%5B0%5D.children%5B0%5D.text%2C'care'%3Acare%5B0%5D.children%5B0%5D.text%2C'tips'%3Atips%5B0%5D.children%5B0%5D.text%2Ctitle%2Cvideo%2Cimage%2Ccategory%2C'prepTitle'%3A%20prep%5B%5D.title%2C'prep'%3A%20prep%5B%5D.text%5B0%5D.children%5B0%5D.text%2C'sequencesTitle'%3Asequences%5B%5D.title%2C'sequences'%3A%20sequences%5B%5D.text%5B0%5D.children%5B0%5D.text%7D",
        {
            method: 'GET',
            headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }
    );
    if(response.ok){
        const json = await response.json();
        
        return{
            kind: 'success',
            body: json.result.sort(function(a:any, b:any) {
                var textA = a.title.toUpperCase();
                var textB = b.title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })
        }
    } else {
        return{
            kind: 'failure',
        }
    }
}