import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import useCachedResources from "./src/hooks/useCachedResources";
import useColorScheme from "./src/hooks/useColorScheme";
import Navigation from "./src/navigation";
import { Provider } from "react-redux";
import { persistor, store } from "./src/store";
import { PersistGate } from "redux-persist/integration/react";
import { LogBox, View, Text, Image } from "react-native";
LogBox.ignoreLogs(["Warning: ..."]); // Ignore log notification by message
import NetInfo from "@react-native-community/netinfo";
import { useState } from "react";
import React from "react";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  let connected: null | boolean = false;
  const [isConnected, setIsConnected] = useState<boolean | null>(null);
  const unsubscribe = NetInfo.addEventListener((network) => {
    if (network.isConnected != isConnected) {
      setIsConnected(network.isConnected);
    }
  });

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SafeAreaProvider>
            <Provider store={store}>
              {!isConnected && (
                <View
                  style={{
                    width: "80%",
                    height: "80%",
                    position: "absolute",
                    zIndex: 1000,
                    alignSelf: "center",
                    marginTop: "20%",
                    borderRadius: 50,
                    shadowColor: "#fff",
                    shadowOffset: {
                      width: 0,
                      height: 12,
                    },
                    shadowOpacity: 1,
                    shadowRadius: 60.0,
                  }}
                >
                  <Image
                    source={require("./src/assets/images/internet.png")}
                    style={{ width: "100%", height: "100%", borderRadius: 50 }}
                  />
                </View>
              )}
              <Navigation colorScheme={colorScheme} />
            </Provider>
            <StatusBar />
          </SafeAreaProvider>
        </PersistGate>
      </Provider>
    );
  }
}
