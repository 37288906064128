import * as ScreenOrientation from "expo-screen-orientation";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  Dimensions,
  Platform,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native";
import { ResizeMode } from "expo-av";
import VideoPlayer from "expo-video-player";
import { Text, View } from "../components/Themed";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { BlurView } from "expo-blur";
import { useWindowDimensions } from "react-native";
import { view } from "../reducers/generalSlice";
import sanityClient from "@sanity/client";
import { StatusBar } from "expo-status-bar";

export default function ModalScreen() {
  const video = React.useRef(null);

  const navigation = useNavigation();
  const [index, setIndex] = useState(0);
  const [visible, setVisible] = useState(true);
  const screenState = useSelector(
    (state: RootState) => state.procedureList.procedures
  );
  const selectedVideo = useSelector(
    (state: RootState) => state.generalList.video
  );
  const consented = useSelector(
    (state: RootState) => state.generalList.consented
  );
  const user = useSelector((state: RootState) => state.generalList);
  const views = useSelector((state: RootState) => state.generalList.viewed);
  const first = !views?.includes(selectedVideo);
  let procedure = screenState.filter((video) => video._id == selectedVideo);
  const Tab = createMaterialTopTabNavigator();
  const [inFullscreen, setInFullscreen] = useState(false);
  const [perform, setPerform] = useState("No");
  const [performed, setPerformed] = useState("Never");

  const dispatch = useDispatch<AppDispatch>();

  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  const client = sanityClient({
    projectId: "bvas6ydi",
    dataset: "production",
    apiVersion: "2022-10-23",
    token:
      "skvcKtSsI2xBekJe6tSzEFW4W2pZ7AloBah3YYPVJNHaNlCWD6ycoKwK6FiyIpkIDkf1XTBJdpbRb7LUXoMHo84WonjfqsQ8bX9c6esvsvqYlLLZFSALMeML4PVej6dSQ41lUjsN9kB75lL3xlW0xPoq5OefFJ03s1AWiKGTaKbYPMQ6qYUd", // or leave blank for unauthenticated usage
    useCdn: false,
  });

  async function updateView() {
    await ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock.PORTRAIT_UP
    );
    await ScreenOrientation.unlockAsync();
  }

  useEffect(() => {
    const focus = navigation.addListener("focus", () => {
      // Orientation.unlockAllOrientations();
      ScreenOrientation.unlockAsync();
      //console.log(j);
      /*console.log("Screen was focused");
      console.log("Screen unlocked");
      ScreenOrientation.unlockAsync();*/
      const subscription = ScreenOrientation.addOrientationChangeListener(
        (e) => {
          console.log("test2");
          if (
            e.orientationInfo.orientation === 3 ||
            e.orientationInfo.orientation === 4
          ) {
            setInFullscreen(true);
            console.log("true");
          } else {
            console.log("false");
            setInFullscreen(false);
          }
        }
      );
    });
    const blur = navigation.addListener("blur", () => {
      ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT_UP
      );
    });
    return () => ScreenOrientation.removeOrientationChangeListeners();
  }, [navigation]);

  /*useEffect(() => {
    navigation.addListener("focus", () => {
      console.log("modal");
      ScreenOrientation.unlockAsync(); /*.then(() => {
          ScreenOrientation.addOrientationChangeListener((e) => {
            console.log("test2");
            if (
              e.orientationInfo.orientation === 3 ||
              e.orientationInfo.orientation === 4
            ) {
              setInFullsreen(true);
              console.log("true");
              return;
            } else {
              console.log("false");
              setInFullsreen(false);
              return;
            }
          });
        });
    }),
      //ScreenOrientation.removeOrientationChangeListeners();
      //ScreenOrientation.lockAsync(3);
  []);*/

  const Header = () => (
    <View
      style={{
        flexDirection: "row",
        height: 50,
        width: windowWidth,
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#00000000",
      }}
    >
      {Platform.OS === "android" && inFullscreen ? (
        <StatusBar hidden={true} />
      ) : null}
      <TouchableOpacity
        style={{ width: 50 }}
        onPress={() => {
          navigation.goBack();
        }}
      >
        <Feather
          size={25}
          style={{ color: "white", padding: 10 }}
          name="arrow-left"
        />
      </TouchableOpacity>
      <Text
        style={{
          marginLeft: 0,
          color: "#FFF",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {procedure[0].title}
      </Text>
      <TouchableOpacity
        style={{ width: 50 }}
        onPress={() => {
          if (inFullscreen) {
            ScreenOrientation.lockAsync(
              ScreenOrientation.OrientationLock.PORTRAIT_UP
            );
            setInFullscreen(false);
          } else {
            setInFullscreen(true);
            ScreenOrientation.lockAsync(
              ScreenOrientation.OrientationLock.LANDSCAPE_LEFT
            );
          }
        }}
      >
        <Feather
          size={20}
          style={{ color: "white", padding: 12 }}
          name="maximize"
        />
      </TouchableOpacity>
    </View>
  );

  var prep: JSX.Element[] = [];
  const length = procedure[0].prep.length;
  for (let i = 2; i < length; i++) {
    prep.push(
      <View
        style={{
          borderBottomWidth: i == length - 1 ? 0 : 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginLeft: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            {procedure[0].prepTitle[i]}
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].prep[i]}
          </Text>
        </View>
      </View>
    );
  }

  var sequence: JSX.Element[] = [];
  const lengthS = procedure[0].sequences.length;
  for (let i = 0; i < lengthS; i++) {
    sequence.push(
      <View
        style={{
          borderBottomWidth: i == lengthS - 1 ? 0 : 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            {procedure[0].sequencesTitle[i]}
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].sequences[i]}
          </Text>
        </View>
      </View>
    );
  }

  const Sequences = () => (
    <ScrollView>
      {sequence}
      {first && <View style={{ height: 100 }}></View>}
    </ScrollView>
  );

  const Post = () => (
    <ScrollView>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Post-procedure care
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].care}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Tips
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].tips}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Discussion
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].discussion}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          paddingBottom: 15,
          borderColor: "#eaeaea",
          paddingTop: 10,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Peer Review
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].peer}
          </Text>
        </View>
      </View>
      <View
        style={{
          paddingTop: 10,
          paddingBottom: 15,
        }}
      >
        <View style={{ marginHorizontal: 15 }}>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            References
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5 }}>
            {procedure[0].references}
          </Text>
        </View>
      </View>
      {first && <View style={{ height: 100 }}></View>}
    </ScrollView>
  );

  const Preparation = () => (
    <ScrollView>
      <View style={{ flexDirection: "row" }}>
        <Feather
          name="check-circle"
          size={18}
          color="black"
          style={{ marginLeft: 15, marginTop: 15 }}
        />
        <View>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 15 }}>
            Indication:
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5, marginRight: 15 }}>
            {procedure[0].prep[0]}
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: "row",
          borderBottomWidth: 1,
          paddingBottom: 10,
          borderColor: "#eaeaea",
        }}
      >
        <Feather
          name="x-circle"
          size={18}
          color="black"
          style={{ marginLeft: 15, marginTop: 5 }}
        />
        <View>
          <Text style={{ fontWeight: "700", marginLeft: 5, marginTop: 5 }}>
            Contraindications (ABSOLUTE/relative):
          </Text>
          <Text style={{ fontWeight: "300", marginLeft: 5, marginRight: 15 }}>
            {procedure[0].prep[1]}
          </Text>
        </View>
      </View>
      {prep}
      {first && <View style={{ height: 100 }}></View>}
    </ScrollView>
  );

  return (
    <View style={styles.container}>
      <VideoPlayer
        style={{
          height: inFullscreen ? windowHeight : 233,
          width: windowWidth,
        }}
        slider={{
          thumbTintColor: "white",
          maximumTrackTintColor: "white",
          minimumTrackTintColor: "white",
        }}
        icon={{
          play: <Feather name="play-circle" color={"white"} size={40} />,
          pause: <Feather name="pause-circle" color={"white"} size={40} />,
          replay: <Feather name="refresh-cw" color={"white"} size={40} />,
        }}
        defaultControlsVisible={Platform.OS == "ios" ? true : false}
        header={<Header />}
        fullscreen={{
          visible: false,
        }}
        playbackCallback={(status) => {
          if (status.didJustFinish) {
            setInFullscreen(false);
            ScreenOrientation.lockAsync(
              ScreenOrientation.OrientationLock.PORTRAIT_UP
            );
          }
        }}
        videoProps={{
          shouldPlay: true,
          resizeMode: ResizeMode.COVER,
          source: { uri: procedure[0].video },
        }}
      />
      <Tab.Navigator
        screenOptions={{
          tabBarScrollEnabled: false,
          tabBarIndicatorStyle: {
            backgroundColor: "#415BFA",
          },
          tabBarStyle: { backgroundColor: "white" },
          tabBarLabelStyle: {
            color: "black",
            textTransform: "none",
            fontWeight: "bold",
          },
        }}
        sceneContainerStyle={{ backgroundColor: "white" }}
      >
        <Tab.Screen name={"Preparation"} component={Preparation} />
        <Tab.Screen name={"Sequence"} component={Sequences} />
        <Tab.Screen name={"Post Procedure"} component={Post} />
      </Tab.Navigator>
      <View
        style={[
          {
            display:
              consented && first && visible && !inFullscreen ? "flex" : "none",
            width: windowWidth - 40,
          },
          styles.blurContainer,
        ]}
      >
        <TouchableOpacity
          style={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 20,
            top: -14,
            right: -14,
            position: "absolute",
          }}
          onPress={() => {
            setVisible(false);
          }}
        >
          <Feather name="x-circle" size={24} color="black" />
        </TouchableOpacity>
        {index == 0 && (
          <View>
            <Text>Are you about to perform this procedure?</Text>
            <View
              style={{
                flexDirection: "row",

                paddingTop: 10,
              }}
            >
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    marginVertical: 0,
                    marginRight: 10,
                    width: (windowWidth - 90) / 2,
                    alignItems: "center",
                  },
                ]}
                onPress={() => {
                  setIndex(1), setPerform("Yes");
                }}
              >
                <Text style={{ color: "white" }}>Yes</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    marginVertical: 0,
                    width: (windowWidth - 90) / 2,
                    alignItems: "center",
                  },
                ]}
                onPress={() => setIndex(1)}
              >
                <Text style={{ color: "white" }}>No</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {index == 1 && (
          <View>
            <Text>
              How many times have you performed the procedure in the past?
            </Text>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    width: (windowWidth - 110) / 4,
                    marginRight: 10,
                    alignItems: "center",
                  },
                ]}
                onPress={() => {
                  doc._type = "research";
                  doc.title = procedure[0].title;
                  client
                    .create(doc)
                    .then((res) => {
                      console.log(res);
                      dispatch(view(selectedVideo));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Text style={{ color: "white" }}>Never</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    width: (windowWidth - 110) / 4,
                    marginRight: 10,
                    alignItems: "center",
                  },
                ]}
                onPress={() => {
                  setPerformed("1-5");
                  let doc: any = {
                    ...user,
                    performed: performed,
                    perform: perform,
                  };
                  doc._type = "research";
                  doc.title = procedure[0].title;
                  client
                    .create(doc)
                    .then((res) => {
                      console.log(res);
                      dispatch(view(selectedVideo));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Text style={{ color: "white" }}>1-5</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    width: (windowWidth - 110) / 4,
                    marginRight: 10,
                    alignItems: "center",
                  },
                ]}
                onPress={() => {
                  setPerformed("5-10");
                  let doc: any = {
                    ...user,
                    performed: performed,
                    perform: perform,
                  };
                  doc._type = "research";
                  doc.title = procedure[0].title;
                  client
                    .create(doc)
                    .then((res) => {
                      console.log(res);
                      dispatch(view(selectedVideo));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Text style={{ color: "white" }}>5-10</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  { width: (windowWidth - 110) / 4, alignItems: "center" },
                ]}
                onPress={() => {
                  setPerformed(">10");
                  let doc: any = {
                    ...user,
                    performed: performed,
                    perform: perform,
                  };
                  doc._type = "research";
                  doc.title = procedure[0].title;
                  client
                    .create(doc)
                    .then((res) => {
                      console.log(res);
                      dispatch(view(selectedVideo));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <Text style={{ color: "white" }}>{">"}10</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    left: 500,
    position: "absolute",
    width: 1200,
    top: 100,
    height: "100%",
  },
  tabBar: {
    flexDirection: "row",
  },
  tabItem: {
    flex: 1,
    alignItems: "center",
    padding: 16,
  },
  blurContainer: {
    marginLeft: 20,
    borderRadius: 10,
    padding: 20,
    bottom: 20,
    shadowColor: "#171717",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
    elevation: 3,
    borderColor: "#eaeaea",
    borderWidth: 1,
    flexDirection: "column",
    position: "relative",
  },
  button: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    backgroundColor: "#171717",
    color: "white",
    marginVertical: 5,
  },
});
